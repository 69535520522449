<template>
    <div class="main-layout">
        <div class="header" >
            <div class="logo" @click="toLanding">
                <img src="/static/img/valdispro-logo.svg" />
            </div>
        </div>

        <div :class="{ 'content': true, [ routeClass ]: true }">
            <slot></slot>

            <section class="footer">
                <div class="row">
                    <div class="col-12 m-col-6">
                        <div class="logo" @click="toLanding">
                            <img src="/static/img/valdispro-logo.svg" />
                        </div>
                    </div>
                    <div class="col-12 m-col-6">
                        <div class="row">
                            <div class="col-12 m-col-6">
                                <p v-html="$t('landing.footer.info')"></p>

                                <br/>
                                <!-- <p>{{ $t( 'landing.footer.follow.copy' ) }}</p>
                                <div class="socials">
                                    <a class="facebook" :href="$t('landing.footer.follow.links[0].url')" target="_blank"><img src="/static/img/facebook.png" /></a>
                                    <a class="instagram" :href="$t('landing.footer.follow.links[1].url')" target="_blank"><img src="/static/img/instagram.png" /></a>
                                </div> -->

                            </div>
                            <div class="col-12 m-col-6 hide-for-small">

                                <p v-for="contactLine in $t('landing.footer.contact')">{{ contactLine }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <br class="hide-for-small" />
                <br class="hide-for-small" />
                <br class="hide-for-small" />

                <div class="row text-center">
                    <div class="col-12">
                        <p><a :href="$t( 'landing.footer.terms-of-use-link' )" target="_blank">{{ $t( 'landing.footer.terms-of-use' ) }}</a> | &copy; 2022 Vemedia</p>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    export default {
        computed: {
            routeClass()
            {
                return 'something';
                // return this.$route.name.toLowerCase() + '-view';
            }
        },

        methods: {
            toLanding()
            {
                this.$router.push( '/' );
            }
        }
    };
</script>
