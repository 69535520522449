import 'babel-polyfill';

import Vue from 'vue';
import App from './App';

import router from '@/app/router';
import store from '@/app/store';
import VuexRouterSync from 'vuex-router-sync';

import VueI18n from 'vue-i18n';
import languageData from '@/app/i18n/languageData.json';

import Axios from 'axios';

const userLang = navigator.language || navigator.userLanguage;

let french = [ 'fr', 'fr-fr', 'fr-be' ];
let lang = ( french.indexOf( userLang.toLowerCase() ) > -1 ? 'fr-be' : 'nl-be' );

const urlLang = new URLSearchParams( window.location.search );
if( urlLang.get( 'lang' ) && ( urlLang.get( 'lang' ).toLowerCase() === 'fr-be' || urlLang.get( 'lang' ).toLowerCase() === 'nl-be' ) )
{
    lang = urlLang.get( 'lang' );
}

store.dispatch( 'setLang', lang );

Vue.use( VueI18n );
const i18n = new VueI18n({
    locale: lang, // set locale
    messages: languageData // set locale messages
});

VuexRouterSync.sync( store, router );

Axios.defaults.baseURL = window._rootData.apiUrl;
Axios.defaults.headers.common.Accept = 'application/json';
Axios.interceptors.response.use(
    response => response,
    ( error ) =>
    {
        return Promise.reject( error );
    }
);

Vue.$http = Axios;
Object.defineProperty( Vue.prototype, '$http', {
    get()
    {
        return Axios;
    }
});

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    i18n,
    components: { App },
    template: '<App/>'
});
