import Vue from 'vue';
import Router from 'vue-router';

import { gsap } from '@/vendor';

import Landing from '@/app/pages/Landing';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/',
            name: 'Landing',
            component: Landing
        },

        {
            path: '/product',
            component: () =>
            {
                return import( /* webpackChunkName: 'product-base' */ '@/app/pages/ProductBase' );
            },
            children: [
                {
                    path: 'natural-sleep',
                    name: 'Slapen',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'slapen' */ '@/app/pages/Slapen' );
                    }
                },
                // {
                //     path: 'healthy-sleep',
                //     name: 'Healthy',
                //     component: () =>
                //     {
                //         return import( /* webpackChunkName: 'healthy' */ '@/app/pages/Healthy' );
                //     }
                // },
                {
                    path: 'relax-recharge',
                    name: 'Relax',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'relax' */ '@/app/pages/Relax' );
                    }
                }
                // {
                //     path: 'stress-focus',
                //     name: 'StressFocus',

                //     component: () =>
                //     {
                //         return import( /* webpackChunkName: 'stress-focus' */ '@/app/pages/StressFocus' );
                //     }
                // }
            ]
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    gsap.set( 'html, body', { scrollTop: 0 });

    next();
});

export default appRouter;
