<template>
    <div class="store-locator-wrapper">

        <div class="row hide-for-medium-up">
            <div class="col-12 store-column">
                <h4>{{ $t('landing.retailers.locator.title') }}</h4>
                <div class="input-wrapper">
                    <form @submit.prevent="onLocationSubmit">
                        <input type="text" v-model="query" :placeholder="$t('landing.storelocator.placeholder')" />
                        <button type="submit" class="darkgreen">{{ $t('landing.retailers.locator.search') }}</button>
                    </form>
                </div>
            </div>
        </div>

        <div class="row desktop-map">
            <div class="col-12 m-col-8">
                <div id="map" ref="map"></div>
            </div>
            <div class="col-12 m-col-4">
                <div class="store-column">
                    <h4 class="hide-for-small">{{ title }}</h4>
                    <div class="input-wrapper hide-for-small">
                        <form @submit.prevent="onLocationSubmit">
                            <input ref="searchbar" type="text" v-model="query" :placeholder="$t('landing.storelocator.placeholder')" />
                            <button type="submit" class="darkgreen">{{ $t('landing.retailers.locator.search') }}</button>
                        </form>
                        <p class="error">{{ storeError }}</p>
                    </div>
                    <div class="search-results styled-scrollbars">
                        <div class="result" v-for="result in results" @click="findStore( result, $event )">
                            <h5><span class="store-name">{{ result.name }}</span><span class="distance"> {{ result.distance.toFixed( 1 ) }}km</span></h5>
                            <p>{{ result.address }}</p>
                            <p>{{ result.city }} - BE</p>
                            <p>{{ result.phone }}</p>
                            <p>{{ result.emailaddress }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    import locationData from '@/app/data/locations.json';
    import { MarkerClusterer, GridAlgorithm } from '@googlemaps/markerclusterer';

    export default {
        props: {
            title: {
                default: '',
                type: String
            }
        },

        data()
        {
            return {
                query: null,
                results: [],
                locations: locationData,
                storeError: null,
                searchLocation: null,
                zoomLevel: 8,
                oldZoom: 8
            };
        },

        watch: {
            query: function( pVal )
            {
                pVal = pVal.replace( /[\D]/g, '' );

                this.query = pVal;
            },

            zoomLevel: function( pVal, pOldVal )
            {
                this.oldZoom = pOldVal;

                // console.table({ 'zoom': this.zoomLevel, 'old': this.oldZoom });

                if( pVal > 13 && pOldVal > 13 )
                {
                    return;
                }
                if( pVal < 13 && pOldVal < 13 )
                {
                    return;
                }

                // console.log( 'RESET', this.map, this.markerCluster );

                this.neutralize();

                this.markers = [];

                if( this.markerCluster )
                {
                    this.markerCluster.removeMarkers( this.markers, false );
                    this.markerCluster.clearMarkers();
                }

                this.markers = [];
                this.makeMarkers();
            }
        },

        mounted()
        {
            this.currentOpenWindow = null;

            this.checkInterval = setInterval( () =>
            {
                if( window.google.maps )
                {
                    clearInterval( this.checkInterval );
                    this.initMap();
                }
            }, 100 );
        },

        methods: {
            initMap()
            {
                let google = window.google;
                this.markers = [];

                this.map = new google.maps.Map( this.$refs.map, {
                    zoom: this.zoomLevel,
                    center: {
                        lat: 50.6401661,
                        lng: 4.6666977
                    }
                });

                this.makeMarkers();

                google.maps.event.addListener( this.map, 'zoom_changed', () =>
                {
                    this.zoomLevel = this.map.zoom;
                });
            },

            makeMarkers()
            {
                for( let i = 0; i < this.locations.length; i++ )
                {
                    let location = this.locations[ i ];
                    let iconImage = {
                        url: this.getIconMarkerURL( location ),
                        // This marker is 20 pixels wide by 32 pixels high
                        scaledSize: new window.google.maps.Size( 25, 25 ),
                        // The origin for this image is (0, 0).
                        origin: new window.google.maps.Point( 0, 0 ),
                        // The anchor for this image is the base of the flagpole at (0, 32).
                        anchor: new window.google.maps.Point( 0, 8 )
                    };
                    let marker = new window.google.maps.Marker({
                        position: {
                            lat: parseFloat( location.lat ),
                            lng: parseFloat( location.lng )
                        },
                        map: this.map,
                        title: location.Title,
                        icon: iconImage
                    });

                    let infowindow = new window.google.maps.InfoWindow({
                        content: this.getInfoWindow( location )
                    });

                    marker.addListener( 'click', () =>
                    {
                        if( this.currentOpenWindow )
                        {
                            this.currentOpenWindow.close();
                        }

                        infowindow.open( this.map, marker );
                        this.currentOpenWindow = infowindow;
                    });

                    this.locations[i].marker = marker;

                    this.markers.push( marker );
                }

                if( this.map.zoom === this.oldZoom )
                {
                    this.makeCluster();
                }
                else if( this.map.zoom < 13 && this.oldZoom >= 13 )
                {
                    this.makeCluster();
                }
            },

            makeCluster()
            {
                /* eslint-disable-next-line */
                this.markerCluster = new MarkerClusterer({ map: this.map, markers: this.markers, algorithm: new GridAlgorithm({ gridSize: 50, maxDistance: 30000 }) });
            },

            neutralize()
            {
                for( let i = 0; i < this.markers.length; i++ )
                {
                    try
                    {
                        this.markers[i].f.setMap( null );
                    }
                    catch
                    {
                        this.markers[i].setMap( null );
                    }
                }

                this.markers = [];
            },

            getInfoWindow( location )
            {
                let content = `
                    <div class="google-maps-info-window">
                        <div class="title">
                            <p class="title-copy">${location.name}</p>
                        </div>
                        <div class="adress">
                            <p>${location.address}<br/>${location.zipcode} ${location.city}</p>
                        </div>
                        <div class="contact">
                            <p>${location.phone}</p>
                            <p>${location.emailaddress}</p>
                        </div>
                    </div>
                `;
                return content;
            },

            getIconMark( pBoolean )
            {
                let imgIcon = '<img class="icon-mark" src="/static/img/googlemaps/x-mark.svg" />';
                if( pBoolean )
                {
                    imgIcon = '<img class="icon-mark" src="/static/img/googlemaps/check-mark.svg" />';
                }
                return imgIcon;
            },

            getIconMarkerURL( location )
            {
                let imageURL = '/static/img/maps-logo.png?v2';
                return imageURL;
            },

            find_closest_marker( event )
            {
                let lat = event.latLng.lat();
                let lng = event.latLng.lng();
                let R = 6371; // radius of earth in km
                let distances = [];
                let closest = -1;

                for( let i = 0; i < this.map.markers.length; i++ )
                {
                    let mlat = this.map.markers[i].position.lat();
                    let mlng = this.map.markers[i].position.lng();
                    let dLat = this.rad( mlat - lat );
                    let dLong = this.rad( mlng - lng );
                    let a = Math.sin( dLat / 2 ) * Math.sin( dLat / 2 ) +
                        Math.cos( this.rad( lat ) ) * Math.cos( this.rad( lat ) ) * Math.sin( dLong / 2 ) * Math.sin( dLong / 2 );
                    let c = 2 * Math.atan2( Math.sqrt( a ), Math.sqrt( 1 - a ) );
                    let d = R * c;
                    distances[i] = d;
                    if( closest === -1 || d < distances[ closest ] )
                    {
                        closest = i;
                    }
                }
            },

            rad( x )
            {
                return x * Math.PI / 180;
            },

            onLocationSubmit()
            {
                if( !this.query || !this.query.match( /([\d])+(, België)?/ ) )
                {
                    this.storeError = this.$t( 'landing.storelocator.zipcode-error' );
                }
                else
                {
                    this.storeError = null;

                    if( this.query.indexOf( 'België' ) === -1 )
                    {
                        this.query += ', België';
                    }
                }

                const geocoder = new window.google.maps.Geocoder();
                geocoder
                    .geocode({ address: this.query })
                    .then( result =>
                    {
                        console.log( 'result', result );
                        let location = {
                            lat: result.results[0].geometry.location.lat(),
                            lng: result.results[0].geometry.location.lng()
                        };

                        this.map.setCenter( location );
                        this.map.setZoom( 13 );

                        this.searchLocation = location;

                        console.log( this.searchLocation );
                        this.sortLocations();
                    })
                    .catch( e =>
                    {
                        // console.log( 'Geocode was not successful for the following reason: ' + e );
                        this.storeError = this.$t( 'landing.storelocator.zipcode-error' );
                    });
            },

            sortLocations()
            {
                this.results = [];

                locationData.sort( ( a, b ) =>
                {
                    let aDist = { lat: a.lat - this.searchLocation.lat, lng: a.lng - this.searchLocation.lng };
                    let bDist = { lat: b.lat - this.searchLocation.lat, lng: b.lng - this.searchLocation.lng };

                    let aSqrt = Math.sqrt( aDist.lat * aDist.lat + aDist.lng * aDist.lng );
                    let bSqrt = Math.sqrt( bDist.lat * bDist.lat + bDist.lng * bDist.lng );

                    a.distance = aSqrt * 110.574; // 1 deg = 110.574km
                    b.distance = bSqrt * 110.574; // 1 deg = 110.574km

                    if( aSqrt < bSqrt )
                    {
                        return -1;
                    }
                    if( aSqrt > bSqrt )
                    {
                        return 1;
                    }

                    return 0;
                });

                for( let i = 0; i < locationData.length; i++ )
                {
                    if( locationData[i].distance > 10 || this.results.length > 10 )
                    {
                        return;
                    }

                    this.results.push( locationData[i] );
                }
            },

            findStore( result, e )
            {
                console.log( e.currentTarget );
                let alreadySelected = this.$el.querySelector( '.selected' );
                if( alreadySelected )
                {
                    alreadySelected.classList.remove( 'selected' );
                }

                e.currentTarget.classList.add( 'selected' );
                this.map.setCenter({ lat: result.lat, lng: result.lng });
                this.map.setZoom( 17 );
            }
        }
    };
</script>
