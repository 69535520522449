<template>
    <div id="app" :class="{ 'is-ios': browserCheck.iOS }">
        <svg-lib></svg-lib>

        <div class="langtoggle" @click="toggleLang">
            <img :src="'/static/img/be-flag.svg'" />
            <span>{{ lang === 'fr-be' ? 'BE (FR)' : 'BE (NL)' }}</span>
        </div>

        <ynk-layout>
            <transition mode="out-in"
                        @enter="getAnimateIn"
                        @leave="getAnimateOut"
                        :css="false"
                        appear>
                <router-view :key="$route.path" ref="currentView" ></router-view>
            </transition>
        </ynk-layout>
    </div>
</template>

<style lang="scss">
    @import './static/scss/app.scss';
</style>

<script>
    import { gsap } from '@/vendor';
    import Main from '@/app/layouts/Main';
    import SvgLib from '@/app/components/SvgLib';
    import { browserCheck } from '@/app/mixins';

    export default {
        name: 'App',

        components: {
            'svg-lib': SvgLib,
            'ynk-layout': Main
        },

        mixins: [ browserCheck ],

        computed: {
            lang()
            {
                return this.$store.getters[ 'lang' ];
            }
        },

        mounted()
        {
            window.addEventListener( 'resize', this.onResize );
            this.onResize();
        },

        methods: {
            toggleLang()
            {
                let newLang = 'fr-be';

                if( this.lang === 'fr-be' )
                {
                    newLang = 'nl-be';
                }

                this.$i18n.locale = newLang;
                this.$store.dispatch( 'setLang', newLang );
            },

            onResize()
            {
                document.documentElement.style.setProperty( '--vh100', window.innerHeight + 'px' ); // fix for 100vh on iphones
            },

            getAnimateIn( el, done )
            {
                let tl = gsap.timeline({ onComplete: done });
                if( typeof this.$refs.currentView.getAnimateIn === 'function' )
                {
                    tl.add( this.$refs.currentView.getAnimateIn(), 0 );
                }
                else
                {
                    done();
                }
            },

            getAnimateOut( el, done )
            {
                let tl = gsap.timeline({ onComplete: done });
                if( typeof this.$refs.currentView.getAnimateOut === 'function' )
                {
                    tl.add( this.$refs.currentView.getAnimateOut(), 0 );
                }
                else
                {
                    done();
                }
            }
        }
    };
</script>
