import Vue from 'vue';
import Vuex from 'vuex';

import entry from './modules/entry';

Vue.use( Vuex );
require( 'es6-promise' ).polyfill();

export default new Vuex.Store({
    strict: true,

    modules: {
        entry
    },

    state: {
        lang: ''
    },

    getters: {
        lang: state => state.lang
    },

    mutations: {
        setLang: ( state, payload ) =>
        {
            state.lang = payload;
        }
    },

    actions: {
        setLang: ( context, payload ) =>
        {
            context.commit( 'setLang', payload );
        }
    }
});
