<template>
    <div class="landing-view">
        <section class="hero">
            <div class="mobile-hero hide-for-medium-up">
                <div class="copy">
                    <img src="/static/img/hero-troela.png" />
                    <h1 v-html="$t('landing.hero.title')"></h1>
                    <p>{{ $t('landing.hero.subtitle') }}</p>
                    <a href="#retailers" class="button darkgreen">{{ $t('landing.hero.button') }}</a>
                </div>
            </div>
            <!-- <img class="mobile-hero hide-for-medium-up" src="/static/img/mobile-hero-bg.jpg" /> -->
            <div class="row hide-for-small">
                <div class="col-12 m-col-6">
                    <h1 v-html="$t('landing.hero.title')"></h1>
                    <p>{{ $t('landing.hero.subtitle') }}</p>
                    <a href="#retailers" class="button darkgreen">{{ $t('landing.hero.button') }}</a>
                </div>

                <div class="col-21 m-col-6">
                    <img src="/static/img/hero-troela.png" />
                </div>
            </div>

            <v-scroll-indicator class="hide-for-small"></v-scroll-indicator>
        </section>

        <section class="gummy-natural" data-section="gummy-natural">
            <div class="row">
                <div class="col-12 m-col-5">
                    <img class="landing-gummy" src="/static/img/gummy-relax-recharge.jpg" />
                </div>

                <div class="col-12 m-col-5">
                    <!-- <h2>Valdispert Natural</h2> -->
                    <h2>{{ $t('landing.gummy-info.title') }}</h2>
                    <p v-html="$t('landing.gummy-info.copy')"></p>
                </div>
            </div>
        </section>

        <section id="products" class="products blue-slant" data-section="products">
            <img class="title-img" src="/static/img/products-title-img.jpg" />
            <h2 v-html="$t('landing.products.title')"></h2>

            <div class="blue-slant-bg"></div>

            <v-slider class="hide-for-medium-up pot-slider product-wrapper" ref="slider" :key="'retailer-slider'" :dots="false" :arrows="true" :startAt="0">
                <div>
                    <div class="slider-content">
                        <div class="product relaxed">
                            <div class="row">
                                <div class="col-12">
                                    <div class="potje-wrapper relax-recharge">
                                        <img class="packshot" src="/static/img/packshots/relax-recharge2.png" />

                                        <!-- <svg viewBox="0 0 569.85 473.65" class="tak2"><use xlink:href="#tak2"></use></svg>
                                        <svg viewBox="0 0 569.85 473.65" class="tak2-2"><use xlink:href="#tak2"></use></svg>
                                        <svg viewBox="0 0 138.49 368" class="battery"><use xlink:href="#battery"></use></svg>
                                        <svg viewBox="0 0 538.55 630.46" class="budda"><use xlink:href="#budda"></use></svg>

                                        <img class="gummies" src="/static/img/floaters/gummies.png" />
                                        <img class="kamille" src="/static/img/floaters/kamille.png" />
                                        <img class="kamille-zijkant" src="/static/img/floaters/kamille-zijkant.png" /> -->
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h3 v-html="$t('landing.products.relax-recharge.title')">Even bijkomen<sup>4</sup></h3>
                                    <p v-html="$t('landing.products.relax-recharge.copy')">Goed voor jezelf zorgen is belangrijk. Na druk, druk, druk, pak je even dat momentje voor jezelf om te relaxen<sup>4</sup> en weer fitter te voelen<sup>5</sup></p>
                                    <router-link class="meer-info darkgreen" to="/product/relax-recharge" tag="button" v-html="$t('landing.products.relax-recharge.button')">Meer info</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="slider-content">
                        <div class="product slapen">
                            <div class="row">
                                <div class="col-12">
                                    <div class="potje-wrapper natural-sleep">
                                        <img class="packshot" src="/static/img/packshots/natural-sleep2.png" />

                                        <!-- <svg viewBox="0 0 120 266" class="tak1"><use xlink:href="#lavendel-tak"></use></svg>

                                        <img class="citronella" src="/static/img/floaters/citronella-small.png" />

                                        <svg viewBox="0 0 567.52 362.18" class="wolkje"><use xlink:href="#wolkje"></use></svg>
                                        <svg class="maan"><use xlink:href="#maan"></use></svg>
                                        <svg viewBox="0 0 613.73 592.75" class="kussen"><use xlink:href="#pillow"></use></svg>

                                        <img class="echinacea" src="/static/img/floaters/echinacea.png" />
                                        <img class="gummies" src="/static/img/floaters/gummies.png" /> -->
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <!-- <h3>Potje sneller in <br/>slaap vallen</h3> -->
                                    <h3 v-html="$t('landing.products.natural-sleep.title')"></h3>
                                    <p v-html="$t('landing.products.natural-sleep.copy')"></p>
                                    <router-link class="meer-info darkgreen" to="/product/natural-sleep" tag="button" v-html="$t('landing.products.natural-sleep.button')"></router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-slider>

            <div class="product-wrapper content hide-for-small">

                <div class="product relaxed">
                    <div class="row">
                        <div class="col-5 m-col-4">
                            <div class="potje-wrapper relax-recharge">
                                <img class="packshot" src="/static/img/packshots/relax-recharge2.png?v2" />

                                <!-- <svg viewBox="0 0 569.85 473.65" class="tak2"><use xlink:href="#tak2"></use></svg>
                                <svg viewBox="0 0 569.85 473.65" class="tak2-2"><use xlink:href="#tak2"></use></svg>
                                <svg viewBox="0 0 138.49 368" class="battery"><use xlink:href="#battery"></use></svg>
                                <svg viewBox="0 0 538.55 630.46" class="budda"><use xlink:href="#budda"></use></svg>

                                <img class="gummies" src="/static/img/floaters/gummies.png" />
                                <img class="kamille" src="/static/img/floaters/kamille.png" />
                                <img class="kamille-zijkant" src="/static/img/floaters/kamille-zijkant.png" /> -->
                            </div>
                        </div>
                        <div class="col-7 col-offset-1 m-col-3">
                            <h3 v-html="$t('landing.products.relax-recharge.title')"></h3>
                            <p v-html="$t('landing.products.relax-recharge.copy')"></p>
                            <router-link class="meer-info darkgreen" to="/product/relax-recharge" tag="button" v-html="$t('landing.products.relax-recharge.button')"></router-link>
                        </div>
                    </div>
                </div>

                <div class="product doordromen">
                    <div class="row">
                        <div class="col-7 col-offset-0 m-col-3 m-col-offset-5">
                            <h3 v-html="$t('landing.products.natural-sleep.title')"></h3>
                            <p v-html="$t('landing.products.natural-sleep.copy')"></p>
                            <router-link class="meer-info darkgreen" to="/product/natural-sleep" tag="button" v-html="$t('landing.products.natural-sleep.button')"></router-link>
                        </div>
                        <div class="col-5 m-col-4">
                            <div class="potje-wrapper natural-sleep">
                                <img class="packshot" src="/static/img/packshots/natural-sleep2.png" />

                                <!--<svg viewBox="0 0 120 266" class="tak1"><use xlink:href="#lavendel-tak"></use></svg>

                                <img class="citronella" src="/static/img/floaters/citronella-small.png" />

                                <svg viewBox="0 0 567.52 362.18" class="wolkje"><use xlink:href="#wolkje"></use></svg>
                                <svg class="maan"><use xlink:href="#maan"></use></svg>
                                <svg viewBox="0 0 613.73 592.75" class="kussen"><use xlink:href="#pillow"></use></svg>

                                <img class="echinacea" src="/static/img/floaters/echinacea.png" />
                                <img class="gummies" src="/static/img/floaters/gummies.png" /> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="retailers" id="retailers" data-section="retailers">
            <div class="row">
                <img class="title-img" src="/static/img/about-title-img.jpg" />
                <h2 v-html="$t('landing.retailers.title')"></h2>
                <div class="retailers-list stress">
                    <a v-for="retailer in $t('landing.retailers.shops')" :class="retailer.name" :href="retailer.url" target="_blank"><img :src="retailer.image" /></a>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <!-- <img class="title-img" src="/static/img/about-title-img.jpg" />
                    <h2>{{ $t('landing.retailers.title') }}</h2> -->

                    <v-storelocator :title="$t('landing.retailers.locator.title')"></v-storelocator>
                </div>
            </div>
        </section>
    </div>
</template>

<style lang="scss">
    section.scrolltrigger{
        opacity: 0;
    }
</style>

<script>
    import { gsap, ScrollTrigger } from '@/vendor';
    import Slider from '@/app/components/Carousel';
    import ScrollIndicator from '@/app/components/ScrollIndicator';
    import StoreLocator from '@/app/components/StoreLocator';

    export default {
        name: 'Landing',

        components: {
            'v-slider': Slider,
            'v-scroll-indicator': ScrollIndicator,
            'v-storelocator': StoreLocator
        },

        data()
        {
            return {
                slickOptions: {
                    arrows: true,
                    dots: true,
                    fade: true,
                    useCSS: false
                }
            };
        },

        mounted()
        {
            /* scrolltriggers */
            let timelines = this.makeTimelines();

            for( let elem of this.$el.children )
            {
                if( timelines[ elem.dataset.section ] )
                {
                    ScrollTrigger.create({
                        animation: timelines[ elem.dataset.section ],
                        trigger: elem,

                        start: 'top 70%'
                    });
                }
            }
        },

        methods: {
            makeTimelines()
            {
                let gummyTl = gsap.timeline({ paused: true });
                gummyTl.from( 'section.gummy-natural img', { opacity: 0, y: 20 }, 0 );
                gummyTl.from( 'section.gummy-natural .col-5:last-of-type', { opacity: 0, x: 20 }, 0.3 );

                let goedeDagTl = gsap.timeline({ paused: true });
                goedeDagTl.from( 'section.goede-dag .row > *', { opacity: 0, y: 20, stagger: 0.1 }, 0 );

                let productsTl = gsap.timeline({ paused: true });
                productsTl.from( 'section.products > img, section.products > h2', { opacity: 0, y: 20, stagger: 0.1 }, 0 );
                productsTl.from( 'section.products > .blue-slant-bg', { opacity: 0 }, 0.3 );
                productsTl.from( 'section.products .product-wrapper .product', { opacity: 0, y: -20, stagger: 0.2 }, 0.3 );

                let aboutTl = gsap.timeline({ paused: true });
                aboutTl.from( 'section.about .col-10 > *', { opacity: 0, y: 20, stagger: 0.1 });

                let timelines = {};
                timelines['gummy-natural'] = gummyTl;
                timelines['goede-dag'] = goedeDagTl;
                timelines['products'] = productsTl;
                timelines['about'] = aboutTl;

                return timelines;
            },

            scrollDown()
            {
                gsap.to( 'body, html', { scrollTop: window.innerHeight, duration: 1, ease: 'sine.inOut' });
            }
        }
    };
</script>
